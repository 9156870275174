@function svg-uri($svg) {
    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: str-replace($chunk, '"', "'");
        $chunk: str-replace($chunk, '<', '%3C');
        $chunk: str-replace($chunk, '>', '%3E');
        $chunk: str-replace($chunk, '&', '%26');
        $chunk: str-replace($chunk, '#', '%23');
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return #{$encoded};
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function _buildIcon($icon) {
  $icon: '#{$icon}';
  @return svg-uri($icon);
}

@function icon(
  $icon-name, 
  $color
  ){
  $icons: (
    chevron-down:   '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M21 5.176l-9.086 9.353-8.914-9.353-2.314 2.471 11.314 11.735 11.314-11.735-2.314-2.471z"/></svg>',
    chevron-left:   '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" enable-background="new 0 0 28 28"><path fill="#{$color}" d="M23,24.7L12.5,14.2L23,3.7L20.3,1L7,14.2l13.3,13.3C20.3,27.5,23,24.7,23,24.7z"></path></svg>',
    chevron-right:  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" enable-background="new 0 0 28 28"><path fill="#{$color}" d="M7,3.8l10.5,10.5L7,24.7l2.7,2.7L23,14.3L9.7,1C9.7,1,7,3.8,7,3.8z"></path></svg>',
    trash:          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" enable-background="new 0 0 27 27"><path fill="#{$color}" d="M27.5,5a2.22,2.22,0,0,1-1.8,2.25H2.3A2.22,2.22,0,0,1,.5,5,2.22,2.22,0,0,1,2.3,2.75H9.5C9.5,1.63,11.07.5,12.2,0.5h3.6c1.13,0,2.7,1.35,2.7,2.25h7.2A2.22,2.22,0,0,1,27.5,5Zm-2.25,5.85V25.7c0,0.9-1.8,1.8-2.7,1.8H5.45c-0.9,0-2.7-.68-2.7-1.8V10.85A3.17,3.17,0,0,1,4.33,9.5H23.9A3.88,3.88,0,0,1,25.25,10.85ZM11.75,14.9a1,1,0,0,0-.9-0.9H10.4a1,1,0,0,0-.9.9v7.2a1,1,0,0,0,.9.9h0.45a1,1,0,0,0,.9-0.9V14.9Zm6.75,0a1,1,0,0,0-.9-0.9H17.15a1,1,0,0,0-.9.9v7.2a1,1,0,0,0,.9.9H17.6a1,1,0,0,0,.9-0.9V14.9Z" transform="translate(-0.5 -0.5)" /></svg>',
    clock:          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,21c-5,0-9-4-9-9c0-5,4-9,9-9s9,4,9,9 C21,17,17,21,12,21z M17.4,14.5l-4.4-3V6.4l-0.1-0.3L12.5,6h-1.2L11,6.1l-0.1,0.3v6l0.1,0.3l0.1,0.1l5.1,3.4l0.3,0.1l0.1,0l0.3-0.2 l0.6-1C17.6,14.9,17.6,14.6,17.4,14.5z"></path></svg>',
    close:          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M19.5 22c-.2 0-.5-.1-.7-.3L12 14.9l-6.8 6.8c-.2.2-.4.3-.7.3-.2 0-.5-.1-.7-.3l-1.6-1.6c-.1-.2-.2-.4-.2-.6 0-.2.1-.5.3-.7L9.1 12 2.3 5.2C2.1 5 2 4.8 2 4.5c0-.2.1-.5.3-.7l1.6-1.6c.2-.1.4-.2.6-.2.3 0 .5.1.7.3L12 9.1l6.8-6.8c.2-.2.4-.3.7-.3.2 0 .5.1.7.3l1.6 1.6c.1.2.2.4.2.6 0 .2-.1.5-.3.7L14.9 12l6.8 6.8c.2.2.3.4.3.7 0 .2-.1.5-.3.7l-1.6 1.6c-.2.1-.4.2-.6.2z"></path></svg>',
    checkmark:      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M23.6 5L22 3.4c-.5-.4-1.2-.4-1.7 0L8.5 15l-4.8-4.7c-.5-.4-1.2-.4-1.7 0L.3 11.9c-.5.4-.5 1.2 0 1.6l7.3 7.1c.5.4 1.2.4 1.7 0l14.3-14c.5-.4.5-1.1 0-1.6z"/></svg>',
    search:         '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" enable-background="new 0 0 16 16"><path fill="#{$color}" d="M0 5.667c0 3.125 2.542 5.667 5.667 5.667 1.202 0 2.315-.38 3.233-1.02l.455.456c-.07.5.082 1.025.466 1.41l3.334 3.332c.326.325.753.488 1.18.488.425 0 .852-.163 1.177-.488.652-.65.652-1.706 0-2.357L12.18 9.822c-.384-.384-.91-.536-1.41-.466l-.454-.456c.64-.918 1.02-2.03 1.02-3.233C11.333 2.542 8.79 0 5.666 0S0 2.542 0 5.667zm2 0C2 3.645 3.645 2 5.667 2s3.667 1.645 3.667 3.667-1.646 3.666-3.667 3.666S2 7.688 2 5.667z"></path></svg>',
    flag:           '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M24 10.1L20 .3c-.2-.5-.7-.6-1.1-.2C14.3 5.5 8.6 0 3.2 3.8L2.9 3C2.6 2.2 1.8 1.8 1 2 .2 2.3-.2 3.1.1 3.9l6.5 18.9c.2.6.8 1 1.4 1 .2 0 .3 0 .5-.1.8-.3 1.2-1.1.9-1.9l-2.6-7.5c5.7-3.4 12.3 2.7 17-3.4.2-.2.2-.5.2-.8z"></path></svg>',
    bell:           '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><g fill-rule="evenodd" clip-rule="evenodd"><path fill="#{$color}" d="M1.5 13.2l.1-.2c.7-1.7 3 0 3.9-2.1l1.1-2.5c1.4-3.3 4.7-5.3 8.3-5.2 0-.1 0-.2.1-.3.6-1.4 2.1-2 3.5-1.4 1.4.6 2 2.1 1.4 3.5 0 0 0 .1-.1.1 2.8 2.3 3.7 6 2.2 9.4L20.9 17c-.9 2.1 1.9 2.5 1.2 4.2l-.1.2c-.2.4-.5.3-.8.1l-7.5-3-2.1-.8-2.1-.8-7.5-3c-.5-.2-.7-.3-.5-.7zM10.4 22.3c-1.4-.6-2.3-2-2.5-3.4 0-.3.3-.6.6-.5l6.1 2.4c.3.1.4.5.1.8-1.1.9-2.8 1.3-4.3.7z"></path></g></svg>',
    exclamation:    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M13.5 0h-2c-.8 0-1.5.7-1.5 1.5v14c0 .8.7 1.5 1.5 1.5h2c.8 0 1.5-.7 1.5-1.5v-14c0-.8-.7-1.5-1.5-1.5z"></path><circle fill="#{$color}" cx="12.5" cy="21.5" r="2.5"></circle></svg>',
    prohibit:       '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 4c1.4 0 2.7.4 3.9 1L12 8.8 8.8 12 5 15.9c-.6-1.1-1-2.5-1-3.9 0-4.4 3.6-8 8-8zm0 16c-1.4 0-2.7-.4-3.9-1l3.9-3.9 3.2-3.2L19 8.1c.6 1.1 1 2.5 1 3.9 0 4.4-3.6 8-8 8z"></path></svg>',
    question:       '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><g><path fill="#{$color}" d="M18.5 4.4c-.9-2.1-2.7-3.7-5-4.2-3.8-.8-7.3 1.6-8.3 5C5 6.1 5.7 7 6.7 7h1.1c.6 0 1.2-.4 1.4-1 .4-1.2 1.5-2 2.8-2 2.1 0 3.9 2.5 2.5 4.6-.4.6-1 1-1.6 1.3-1.7.6-2.9 2.1-2.9 3.8v2.8c0 .8.7 1.5 1.5 1.5h1c.8 0 1.5-.7 1.5-1.5v-2.1c0-.4.3-.8.6-.9 3.4-1.4 5.4-5.3 3.9-9.1z"></path><circle fill="#{$color}" cx="12" cy="22" r="2"></circle></g></svg>',
    product:        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" enable-background="new 0 0 16 16"><path fill="#{$color}" d="M15.087 0H10.01c-.554 0-1.085.235-1.476.627L.3 8.87c-.37.368-.37.965 0 1.327l5.553 5.556c.362.368.955.37 1.323 0L15.4 7.52c.39-.392.6-.922.6-1.476V.967C16 .45 15.604 0 15.087 0zm-2.89 5.56c-.94 0-1.702-.764-1.702-1.703 0-.94.763-1.702 1.702-1.702.94 0 1.702.763 1.702 1.702 0 .94-.764 1.702-1.703 1.702z"/></svg>',
    preview:        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" enable-background="new 0 0 500 500"><path fill="#{$color}" d="M497.9 237.5C447.9 129.2 352.1 58.3 250 58.3S52.1 129.2 2.1 237.5c-4.2 8.3-4.2 18.8 0 27.1 50 108.3 145.8 179.2 247.9 179.2S447.9 373 497.9 264.6c2.1-10.4 2.1-18.8 0-27.1zM250 345.8c-52.1 0-95.8-43.8-95.8-95.8s43.8-95.8 95.8-95.8 95.8 43.8 95.8 95.8-43.7 95.8-95.8 95.8z"/></svg>',
    edit:           '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><g><path fill="#{$color}" d="M19.758 8.15L8.656 19.25l-3.89-3.89L15.87 4.26zM22.4 3.2l-1.6-1.6c-.6-.6-1.7-.6-2.3 0l-1.2 1.2 3.9 3.9 1.2-1.2c.6-.6.6-1.7 0-2.3zM3.4 16.8l-.3.3c-.2.2-.3.4-.4.7l-1.4 4.3c-.1.4.3.8.7.7l4.3-1.4c.3-.1.5-.2.7-.4l.3-.3-3.9-3.9z"/></g></svg>',
    photos:         '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" enable-background="new 0 0 80 80"><path fill="#{$color}" d="M80 57.6l-4-18.7v-23.9c0-1.1-.9-2-2-2h-3.5l-1.1-5.4c-.3-1.1-1.4-1.8-2.4-1.6l-32.6 7h-27.4c-1.1 0-2 .9-2 2v4.3l-3.4.7c-1.1.2-1.8 1.3-1.5 2.4l5 23.4v20.2c0 1.1.9 2 2 2h2.7l.9 4.4c.2.9 1 1.6 2 1.6h.4l27.9-6h33c1.1 0 2-.9 2-2v-5.5l2.4-.5c1.1-.2 1.8-1.3 1.6-2.4zm-75-21.5l-3-14.1 3-.6v14.7zm62.4-28.1l1.1 5h-24.5l23.4-5zm-54.8 64l-.8-4h19.6l-18.8 4zm37.7-6h-43.3v-51h67v51h-23.7zm25.7-7.5v-9.9l2 9.4-2 .5zm-52-21.5c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm-13-10v43h59v-43h-59zm57 2v24.1l-12.8-12.8c-3-3-7.9-3-11 0l-13.3 13.2-.1-.1c-1.1-1.1-2.5-1.7-4.1-1.7-1.5 0-3 .6-4.1 1.7l-9.6 9.8v-34.2h55zm-55 39v-2l11.1-11.2c1.4-1.4 3.9-1.4 5.3 0l9.7 9.7c-5.2 1.3-9 2.4-9.4 2.5l-3.7 1h-13zm55 0h-34.2c7.1-2 23.2-5.9 33-5.9l1.2-.1v6zm-1.3-7.9c-7.2 0-17.4 2-25.3 3.9l-9.1-9.1 13.3-13.3c2.2-2.2 5.9-2.2 8.1 0l14.3 14.3v4.1l-1.3.1z"/></svg>',
    addition:       '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M22.5 10H14V1.5c0-.8-.7-1.5-1.5-1.5h-1c-.8 0-1.5.7-1.5 1.5V10H1.5c-.8 0-1.5.7-1.5 1.5v1c0 .8.7 1.5 1.5 1.5H10v8.5c0 .8.7 1.5 1.5 1.5h1c.8 0 1.5-.7 1.5-1.5V14h8.5c.8 0 1.5-.7 1.5-1.5v-1c0-.8-.7-1.5-1.5-1.5z"/></svg>',
    subtraction:    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" enable-background="new 0 0 12 12"><path d="M6 0"/><path fill="#{$color}" d="M.8 7C.3 7 0 6.7 0 6.2v-.4c0-.5.3-.8.8-.8h10.5c.4 0 .7.3.7.8v.5c0 .4-.3.7-.8.7H.8z"/></svg>',
    calendar:       '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" enable-background="new 0 0 24 24"><path fill="#{$color}" d="M21 3h-1V2c0-1.1-.9-2-2-2s-2 .9-2 2v1H8V2c0-1.1-.9-2-2-2S4 .9 4 2v1H3C1.3 3 0 4.3 0 6v15c0 1.7 1.3 3 3 3h18c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3zM3 21V10h18v11H3z"/></svg>',
    add-circle:     '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" enable-background="new 0 0 12 12"><path fill="#{$color}" d="M6 0c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm3 7h-2v2c0 .5-.5 1-1 1s-1-.5-1-1v-2h-2c-.5 0-1-.5-1-1s.5-1 1-1h2v-2c0-.5.5-1 1-1s1 .5 1 1v2h2c.5 0 1 .5 1 1s-.5 1-1 1z"/></svg>',
    orders:         '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" enable-background="new 0 0 16 16"><g><path fill="#{$color}" d="M13.992 0H2.1C.94 0 0 .94 0 2.1v12.244C0 15.305.785 16 1.75 16H14.34c.964 0 1.658-.694 1.658-1.658V2.1C16 .94 15.15 0 13.992 0zM14 2v8h-1.757C11.28 10 10 11.28 10 12.243v.7c0 .193.337.057.144.057H5.247c-.193 0-.247.136-.247-.057v-.7C5 11.28 4.113 10 3.148 10H2V2h12zM7.117 9.963c.167.16.437.16.603.002l5.17-5.042c.165-.16.165-.422 0-.583l-.604-.583c-.166-.16-.437-.16-.603 0L7.42 7.924 5.694 6.24c-.166-.16-.437-.16-.603 0l-.604.582c-.166.162-.166.423 0 .584l2.63 2.557z"/></g></svg>',
    profile:        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" enable-background="new 0 0 29 29"><path fill="#{$color}" d="M25.35,4.76A14.56,14.56,0,0,0,.44,15,14.55,14.55,0,0,0,15,29.55,14.56,14.56,0,0,0,25.35,4.76Zm3,11.53c0,0.21-.06.41-0.08,0.61s-0.05.42-.09,0.63-0.14.61-.22,0.92c0,0.1,0,.2-0.08.31a13.37,13.37,0,0,1-3.43,5.78,57.32,57.32,0,0,0-7-2.83v-3s1.84-.61,1.84-3.67c1,0,1-2.44,0-2.44,0-.3,1-2.58.61-4.28-0.61-2.44-7.33-2.44-7.94,0-3.06-.61-1.23,3.87-1.23,4.28-1,0-1,2.44,0,2.44,0,3.05,1.84,3.67,1.84,3.67v3a53.25,53.25,0,0,0-7,2.84l-0.09-.08A13.24,13.24,0,0,1,1.67,13.35c0-.33.08-0.67,0.15-1h0A13.44,13.44,0,0,1,28.44,15c0,0.1,0,.2,0,0.29C28.42,15.65,28.41,16,28.38,16.29Z" transform="translate(-0.44 -0.45)"/></svg>'
  );
  
  $icon: _buildIcon(map-get($icons, $icon-name));
  @return url('data:image/svg+xml;charset=utf8,#{$icon}');
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */

  &.icon-prev {
    background-image: icon(chevron-left, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(chevron-left, 'rgb(195,207,216)');
    }
  }
  &.icon-next {
    background-image: icon(chevron-right, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(chevron-right, 'rgb(195,207,216)');
    }
  }
  &.icon-trash {
    background-image: icon(trash, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(trash, 'rgb(195,207,216)');
    }
  }
  &.icon-clock {
    background-image: icon(clock, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(clock, 'rgb(195,207,216)');
    }
  }
  &.icon-close {
    background-image: icon(close, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(close, 'rgb(195,207,216)');
    }
  }
  &.icon-checkmark {
    background-image: icon(checkmark, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(checkmark, 'rgb(195,207,216)');
    }
  }
  &.icon-search {
    background-image: icon(search, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(search, 'rgb(195,207,216)');
    }
  }
  &.icon-flag {
    background-image: icon(flag, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(flag, 'rgb(195,207,216)');
    }
  }
  &.icon-bell {
    background-image: icon(bell, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(bell, 'rgb(195,207,216)');
    }
  }
  &.icon-exclamation {
    background-image: icon(exclamation, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(exclamation, 'rgb(195,207,216)');
    }
  }
  &.icon-question {
    background-image: icon(question, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(question, 'rgb(195,207,216)');
    }
  }
  &.icon-prohibit {
    background-image: icon(prohibit, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(prohibit, 'rgb(195,207,216)');
    }
  }
  &.icon-product {
    background-image: icon(product, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(product, 'rgb(195,207,216)');
    }
  }
  &.icon-preview {
    background-image: icon(preview, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(preview, 'rgb(195,207,216)');
    }
  }
  &.icon-edit {
    background-image: icon(edit, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(edit, 'rgb(195,207,216)');
    }
  }
  &.icon-addition {
    background-image: icon(addition, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(addition, 'rgb(195,207,216)');
    }
  }
  &.icon-subtraction {
    background-image: icon(subtraction, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(subtraction, 'rgb(195,207,216)');
    }
  }
  &.icon-calendar {
    background-image: icon(calendar, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(calendar, 'rgb(195,207,216)');
    }
  }
  &.icon-addcircle {
    background-image: icon(add-circle, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(add-circle, 'rgb(195,207,216)');
    }
  }
  &.icon-orders {
    background-image: icon(orders, 'rgb(50,158,204)');

    &.disabled, &:disabled {
      background-image: icon(orders, 'rgb(195,207,216)');
    }
  }
  &.icon-photos {
    background-image: icon(photos, 'rgb(50,158,204)');
    width: 6.4rem;
    height: 6.4rem;
    background-size: 64px 64px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */

    &.disabled, &:disabled {
      background-image: icon(photos, 'rgb(195,207,216)');
    }
  }
  &.icon-profile {
    background-image: icon(profile, 'rgb(50,158,204)');
    width: 6.4rem;
    height: 6.4rem;
    background-size: 64px 64px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */

    &.disabled, &:disabled {
      background-image: icon(profile, 'rgb(195,207,216)');
    }
  }
}
