html, body {
    min-height: 100%;
    position: relative;
    height: auto;
}

section .card {
    .card-indicator {
        width: 3rem;
        display: inline-block;

        &::before {
            content: '•';
            width: 4rem;
            left: 0;
            text-align: left;
            font-size: 3rem;
            line-height: 0;
            vertical-align: middle;
            display: inline-block;
            margin-top: 0;
        }
    }

    &.stacked {
        margin: 0;
        border-radius: 0;
        box-shadow: none;
    }
}

section.stacked {
    flex-direction: column
}

section.stacked + section.stacked {
    padding-top:0;
}

.card.stacked + .card.stacked {
    border-top:1px solid #ebeef0;
}

.button {
    &.small {
        padding: 0.6rem 1rem;
    }
}


section {
    &.thin-width {
        max-width: 724px;
    }
}


main {
    &.with-sidebar {
        padding-right: 380px;
    }
}

aside.sidebar-background {
    z-index: 195;
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 380px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    background-color: #fafbfc;
}

aside.sidebar {
    z-index: 195;
    width: 100%;
    float:right;
    background: #f5f6f7;
    position: absolute;
    right: 0;
    max-width: 380px;
    top: 0;
    bottom: 0;

    table.summary th {
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: bold;
        text-transform: uppercase;
        padding-bottom: 1.5rem;

        span {
            margin-top: 0;
            font-weight: normal;
        }
        &:hover {
            background: transparent;
            color: inherit;
            cursor: default;
        }
    }
}
