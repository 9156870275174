/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 300;
}
h1 { font-size: 4.0rem; line-height: 1.2; }
h2 { font-size: 3.6rem; line-height: 1.25; }
h3 { font-size: 3.0rem; line-height: 1.3; }
h4 { font-size: 2.4rem; line-height: 1.35; }
h5 { font-size: 1.8rem; line-height: 1.5; }
h6 { font-size: 1.5rem; line-height: 1.6; }

/* Larger than phablet */
@media (min-width: 550px) {
  h1 { font-size: 5.0rem; }
  h2 { font-size: 4.2rem; }
  h3 { font-size: 3.6rem; }
  h4 { font-size: 3.0rem; }
  h5 { font-size: 2.4rem; }
  h6 { font-size: 1.5rem; }
}
