/* Buttons
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  background-color: #0078bd;
  color: #fff;
  border: .1rem solid;
  border-color: #0078bd;
  padding: .9rem 1.5rem;
  margin: 0;
  border-radius: .3rem;
  font-size: 1.4rem;
  line-height: 1;
  height: auto;
  white-space: nowrap;
  text-transform: none;
  font-family: -apple-system, "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  font-weight: normal;
  position: relative;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  
  &:hover,
  &:focus {
    color: #fff;
    outline: none;
    text-decoration: none;
    border-color: #0069a6;
    background-color: #0069a6;
  }
  &:active {
    box-shadow: 0 .3rem .6rem rgba(0,0,0,0.2) inset;
    background-color: #0069a6;
  }
  &.secondary {
    border-color: #d3dbe2;
    background-color: #fff;
    color: #479ccf;
    
    &:hover ,
    &:focus {
      border-color: #d3dbe2;
      background-color: #f5f6f7;
      color: #479ccf;
    }
    &:active {
      box-shadow: 0 .3rem .6rem rgba(0,0,0,0.1) inset;
      background-color: #f5f6f7;
    }
  }
  &.warning {
    background-color: #ff5d5d;
    border-color: #ff5d5d;

    &:hover {
      border-color: #d83e3e;
      background-color: #d83e3e;
    }
  }
  &.link {
    background: transparent;
    border: 0;
    color: #4293c2;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  &.disabled,
  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    cursor: not-allowed;
    box-shadow: none;
    background-color: #fafbfc;
    color: #c3cfd8;
    border: .1rem solid;
    border-color: #d3dbe2;
  }
  &[class^="icon-"],
  &[class*=" icon-"] {
    padding-left: 2.1rem;
    padding-right: 2.1rem;
    min-height: 3.4rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
  }
}
.button-group {
  margin-right: 1rem;
  
  .button,
  button {
    float: left;
    border-right-width: 0;
    border-radius: 0;

    &:hover,
    &:focus,
    &:active {
      border-right-width: 0;
    }
    &:first-child {
      border-top-left-radius: .3rem;
      border-bottom-left-radius: .3rem;
    }
    &:last-child {
      border-right-width: .1rem;
      border-top-right-radius: .3rem;
      border-bottom-right-radius: .3rem;
    }
  }
}