/* Grid
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.row,
.column,
.columns {
  margin-bottom: 1.5rem;  
}
.row {

  &:only-child {
    margin-bottom: 0;
  }
}
.column,
.columns {
  width: 100%;
  float: left;
  box-sizing: border-box; 

  /* For devices larger than 550px */
  @media (min-width: 550px) {
    margin-left: 2%;
    margin-bottom: 0; 

    &:first-child,
    &:only-child {
      margin-left: 0; 
    }
    &.one,
    &.one       { width: 8.16666666667%;       }
    &.two       { width: 16.3333333333%;       }
    &.three     { width: 24.5%;                }
    &.four      { width: 32.6666666667%;       }
    &.five      { width: 40.8333333333%;       }
    &.six       { width: 49%;                  }
    &.seven     { width: 57.1666666667%;       }
    &.eight     { width: 65.3333333334%;       }
    &.nine      { width: 73.5%;                }
    &.ten       { width: 81.6666666667%;       }
    &.eleven    { width: 89.8333333334%;       }
    &.twelve    { width: 100%; margin-left: 0; }

    &.one-third  { width: 32.6666666667%;       }
    &.two-thirds { width: 65.3333333333%;       }

    &.one-half   { width: 49%;                  }
  }
}