/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.full-width {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
.max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-left {
  text-align: right;
}
.highlight-warning {
  background: #fffbe9;
  color: #e85b0f;
}
.helper {
  float: right;
  font-size: 1.3rem;
}
.blue {
  color: #3e89b5;
}
.green {
  color: #4d684b;
}
.yellow {
  color: #6f4f26;
}
.red {
  color: #8c2929;
}
.grey {
  color: #798c9c;
}
.lightblue {
  color: #647f9e;
}
.orange {
  color: #7f3a07;
}
.overflow-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
