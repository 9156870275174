/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  padding: .5rem 1rem;
  background-color: #fff;
  border: .1rem solid #d3dbe2;
  border-radius: .3rem;
  color: #31373d;
  box-sizing: border-box;
  display: block;
  width: 100%;
  line-height: 2.2rem;
  min-width: 7.5rem;
  vertical-align: baseline;
  height: auto;
  margin: 0;
  max-width: 100%;
  font-family: -apple-system, "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  box-shadow: none;
  box-sizing: border-box;
  
  &:focus {
    border: .1rem solid #479ccf;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #fafbfc;
    border: .1rem solid #ebeef0;
    color: #c3cfd8;
  }
  .error & {
    border-color: #ff5d5d;
    background-color: #fef6f5;
  }
  &[type=search] {
    padding-left: 3.0rem;
    background-image: icon(search, 'rgb(149,167,183)');
    background-repeat: no-repeat;
    background-size: 18px 18px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
    background-position: top .6rem left .6rem;
  }
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="radio"],
input[type="checkbox"],
textarea,
select {
  @include appearance(none);
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  min-height: 3.4rem;
}
select {
  padding-right: 3.2rem; 
  background-image: icon(chevron-down, 'rgb(121,140,156)');
  background-size: 12px 12px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
  background-repeat: no-repeat;
  background-position: right 1.0rem top 1.0rem;

  &[multiple] {
    padding-right: 1rem;
    background-image: none;
  }
  &::-ms-expand {
    display: none;
  }
}
textarea {
  min-height: 6.5rem;
  padding-top: .6rem;
  padding-bottom: .6rem;
}
label,
legend {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600;
}
fieldset {
  padding: 0;
  border-width: 0;
}
input[type="checkbox"],
input[type="radio"] {
  position: relative;
  display: inline-block;
  height: 1.6rem;
  width: 1.6rem;
  border: .1rem solid #d3dbe2;
  background-color: #fff;
  margin: 0 1rem 0 0;
  padding: 0;  
  box-sizing: border-box;
  vertical-align: text-bottom;
  
  &:checked:after {
    content: '';
    position: absolute;
  }
  &:active {
    border: .1rem solid #479ccf;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #fafbfc;
    border: .1rem solid #ebeef0;
    color: #c3cfd8;
  } 
}
input[type="checkbox"] {
  border-radius: .1rem;

  &:disabled:checked:after {
    background-image: icon(checkmark, 'rgb(195,207,216)');
  }
  &:checked:after {
    width: 1.2rem;
    height: 1.2rem;
    background-image: icon(checkmark, 'rgb(71,156,207)');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
    top: .1rem;
    left: .1rem;    
  }
}
input[type="radio"] {
  border-radius: .8rem;
  
  &:disabled:checked:after {
    background-color: #c3cfd8;
  }
  &:checked:after {
    width: .8rem;
    height: .8rem;
    background-color: #479ccf;
    border-radius: .5rem;
    top: .3rem;
    left: .3rem;    
  }
}
label {
  display: block;
  margin-top: .5rem;
  color: #31373d;
  font-weight: normal;
  
  &.error,
  .error & {
    color: #ff5d5d;
  }
}
.input-group {
  @include display(flex);
  @include align-items(center);

  .append {
    @include flex(0 0 auto);
    color: rgba(49,55,61,.85);
    line-height: 2.2rem;
    padding: .5rem 1rem;
    border: .1rem solid #d3dbe2;
    background-color: #f5f5f5;
    border-radius: .3rem;
  }
  select,
  input,
  .append,
  .button,
  button {
    border-left-width: 0;

    &:first-child {
      border-left-width: .1rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
  .button,
  button {
    @include flex(0 0 auto);
  }
}
.side-elements {
  @include display(flex);
  @include align-items(center);
  
  label {
    @include flex(0 0 auto);
    margin: 0 1rem 0 0;
    white-space: nowrap;  
  }
  button,
  .button {
    @include flex(0 0 auto);
    margin-left: 1rem;
  }
}