/**
 *  ESC Navindexer styles.
 *
 */

@import 'uptown/uptown';
@import 'uptown_extensions';


header {
    background: #212529;
    color: white;
}

footer .shop-id-information {
    font-size: 10px;
    opacity: 0.5;
    padding: 10px 0;
}

.text-muted {
    color: #666;
}


table td.shrink {
    white-space:nowrap
}
table td.expand {
    white-space:nowrap;
    width: 99%
}



body.show-overuse-alert {
    background: #333333;
    .page-container {
        pointer-events: none;
        filter: blur(2px) brightness(20%);
    }

    .overuse-alert {
        display: block;
    }
}


.overuse-alert {

    display: none;
    @extend section;
    position: fixed;
    top: 10%;
    left: 25%;
    width: 50%;
    z-index:999;

    > div {
        @extend .card;

        h1 {
            text-align: center;
             font-size: 3rem;
        }
        h2 {
            text-align: center;
            font-size: 2rem;
        }
        h3 {
            font-size: 2.5rem;
        }
        h4 {
            text-align: center;
            font-size: 2rem;
        }
    }
}


.overuse-alert-bar {
    display: block;
    text-align: center;
    padding: 1rem;
    background: #800000;
    color: white;

    a {
        color: white;
        text-decoration: underline;
    }
}


.beta-unavailable-notice {
    position:relative;

    &::after {
        content: '';
        background: rgba(255,255,255,0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:hover::after {
        content: 'This feature is not yet available in the beta version.';
        background: rgba(255,255,255,0.7);
        text-align: center;
        padding: 4rem;
    }
}


.template-editor-files {
    list-style: none;
    padding: 0;
    margin: 0;

    .template-link {
        color: black;
    }

    li {
        display: block;

        .delete {
            float: right;
            background: none;
            padding: 0;
            margin: 0;
            border: none;
            color: black;
            opacity: 0.5;
        }

        .add-new {
            background: none;
            padding: 0;
            margin: 0;
            border: none;
            color: #0f95e4;
            opacity: 0.8;
        }

        .add-new,
        .delete {
            &:active {
                box-shadow: none;
                opacity: 1.0;
            }
            &:hover {
                opacity: 0.9;
            }
        }
    }
}


input[readonly] {
    background: #fafbfc;
}


.tag-input {
    padding: .5rem 1rem;
    background-color: #fff;
    border: .1rem solid #d3dbe2;
    border-radius: .3rem;
    color: #31373d;
    box-sizing: border-box;
    display: block;
    width: 100%;
    line-height: 2.2rem;
    min-width: 7.5rem;
    vertical-align: baseline;
    height: auto;
    margin: 0;
    max-width: 100%;
    font-family: -apple-system, "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
    box-shadow: none;
    box-sizing: border-box;

    .tag-input--tag {
        border: 1px solid #CCC;
        padding: 0.5rem;
        border-radius: 4px;
        margin-right: 10px;
        position: relative;
        padding-right: 2rem;
    }

    .tag-input--delete {
        position: absolute;
        right: 0.5rem;
        top: 0.1rem;
        color: #666;

        &:hover {
            color: #980000;
            cursor: pointer;
        }
    }

    .tag-input--text-input {
        display: inline-block;
        width: auto;
        padding: 0;
        margin: 0;
        border: 1px solid transparent;;
        border-bottom: 1px dashed #ccc;
    }
}


.color-picker {
    cursor: default;
    background: white;
    padding: 1rem;
    border: 1px solid grey;
    border-radius: 4px;
    display: inline-block;

    &--canvas {
        width: 10rem;
        height: 10rem;
        background: magenta;
        position: relative;

        &--color,
        &--white,
        &--black {
            pointer-events: none;
            width: 100%;
            height: 100%;
            position: absolute;
        }
        &--white {
            background: linear-gradient(to right, #fff,rgba(255,255,255,0));
        }
        &--black {
            background: linear-gradient(to top, #000,rgba(0,0,0,0));
        }
    }


    &--pointer,
    &--hue--tracker{
        width: 1rem;
        height: 1rem;
        margin-top: -0.5rem;
        margin-left: -0.5rem;

        border: 2px solid white;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius:999px;
        box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        pointer-events: none;
    }

    &--hue {
        width: 100%;
        position: relative;

        &--bar {
            background: #{'linear-gradient(90deg, #f00 0,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,#f00)'};
            margin-top: 0.5rem;
            height: 1rem;
        }
    }
}


div.color-editor--input {
    position: relative;

    .preview {
        position: absolute;
        right: 0.25rem;
        top: 50%;
        height: 2rem;
        width: 6rem;
        margin-top: -1rem;
    }

    .editor {
        display: none;
    }
    &.show-editor .editor {
        display: block;
    }
}
